import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import React from "react";
import { connectHierarchicalMenu } from "react-instantsearch-dom";
import { printNumbersWithSpaceAsThousandsSeparator } from "../../helpers";

const MyHierarchicalMenu = ({ items, refine, createURL, filterIndexes }) => (
  <>
    {items.map((item, index) => (
      <React.Fragment key={item.label}>
        <div className="relative flex items-start">
          <div className="flex items-center h-5">
            <input
              autoComplete="off"
              checked={item.isRefined}
              id={item.label + "-" + index}
              name={item.label + "-" + index}
              type="checkbox"
              onChange={() => refine(item.value)}
              className="focus:ring-qe-orange h-4 w-4 text-qe-orange border-gray-300 rounded"
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              htmlFor={item.label + "-" + index}
              className={`text-gray-700 ${
                item.isRefined ? "font-medium" : null
              }`}
            >
              {item.label}
            </label>

            <span className="ml-3 inline-flex items-center px-1 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
              {printNumbersWithSpaceAsThousandsSeparator(item.count)}
            </span>
          </div>
        </div>
        {item.items && (
          <div className="pl-5 pt-3">
            <MyHierarchicalMenu
              items={item.items}
              refine={refine}
              createURL={createURL}
            />
          </div>
        )}
      </React.Fragment>
    ))}
  </>
);

export const CustomHierarchicalMenu =
  connectHierarchicalMenu(MyHierarchicalMenu);
