import React from "react";
import { connectCurrentRefinements } from "react-instantsearch-dom";

const RefinementTag = ({ item, refine }) => {
  if (item.id === "boundingBox") return null;
  let label = item.label;
  if (label.indexOf("$$$") != -1) {
    const _split = label.split("$$$");

    label = _split[1];
  }
  return (
    <span className="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-qe-light-blue text-white mr-2 mt-2">
      {item.currentRefinement ? item.currentRefinement : label}
      <button
        onClick={() => refine(item.value)}
        type="button"
        className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-white hover:bg-qe-medium-blue"
      >
        <span className="sr-only">
          Supprimer le filtre "
          {item.currentRefinement ? item.currentRefinement : label}"
        </span>
        <svg
          className="h-2 w-2"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 8 8"
        >
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
  );
};

export const CustomCurrentRefinements = connectCurrentRefinements(
  ({ items, refine }) =>
    items.map((item) =>
      item.items ? (
        item.items.map((nested) => (
          <RefinementTag key={nested.label} item={nested} refine={refine} />
        ))
      ) : (
        <RefinementTag key={item.label} item={item} refine={refine} />
      )
    )
);
