import React from "react";
import { PlusIcon, InformationCircleIcon } from "@heroicons/react/solid";
import Card from "../card/card";
import { ClearRefinements } from "react-instantsearch-dom";

export default function Example() {
  return (
    <div className="mt-10">
      <Card>
        <div className="text-center">
          <InformationCircleIcon className="mx-auto h-12 w-12 text-gray-400" />

          <h3 className="mt-2 text-sm font-medium text-gray-900">
            Aucun r&eacute;sultat
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Nous vous sugg&eacute;rons de v&eacute;rifier les paramètres de la
            recherche
          </p>
          <div className="mt-6 flex justify-center">
            <ClearRefinements
              clearsQuery
              translations={{
                reset: "Réinitialiser la recherche",
              }}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}
