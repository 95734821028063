import { Disclosure } from "@headlessui/react";
import { DownloadIcon, MinusIcon, PlusIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { getEffectif } from "../../helpers";
import { CustomHierarchicalMenu } from "../hierarchical-menu/hierarchical-menu";
import { CustomRefinement } from "../refinement-list/refinement-list";
import { sendAnalyticsClick } from "../../analytics/analytics";

export const Facets = (searchState) => {

  const getAnalyticsType = (type) => {
    sendAnalyticsClick('Body', 'click_choice_' + type)
  }


  return (
    <>
      <div className="pb-5 border-b border-gray-200 mb-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Affinez votre recherche
        </h3>
      </div>
      <Disclosure defaultOpen={true} as="div" className="mt-2">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white bg-qe-light-blue rounded-lg hover:bg-qe-medium-blue focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
              <span>Type de bâtiment :</span>
              {!open && <PlusIcon className="w-5 h-5 text-white" />}
              {open && <MinusIcon className="w-5 h-5 text-white" />}
            </Disclosure.Button>
            <Disclosure.Panel onClick={() => {getAnalyticsType('type_batiment')}} className="px-4 pt-4 pb-2 text-sm text-gray-500">
              <CustomHierarchicalMenu
                attributes={[
                  "typeOfWork.lvl0",
                  "typeOfWork.lvl1",
                  "typeOfWork.lvl2",
                ]}
                showMore
                showMoreLimit={100}
                translations={{
                  showMore(expanded) {
                    return expanded ? "Voir moins" : "Voir plus";
                  },
                }}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div className="pb-5 border-b border-gray-200 mt-8 mb-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Recherchez par lot (appel d'offre)
        </h3>
      </div>
      <Disclosure defaultOpen={true} as="div" className="mt-2">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white bg-qe-light-blue rounded-lg hover:bg-qe-medium-blue focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
              <span> Dénomination des lots :</span>
              {!open && <PlusIcon className="w-5 h-5 text-white" />}
              {open && <MinusIcon className="w-5 h-5 text-white" />}
            </Disclosure.Button>
            <Disclosure.Panel onClick={() => {getAnalyticsType('denomination_lot')}} className="px-4 pt-4 pb-2 text-sm text-gray-500">
              <CustomHierarchicalMenu
                attributes={["LOTtypeOfWork.lvl1", "LOTtypeOfWork.lvl2"]}
                showMore
                showMoreLimit={100}
                translations={{
                  showMore(expanded) {
                    return expanded ? "Voir moins" : "Voir plus";
                  },
                }}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="pb-5 border-b border-gray-200 mt-8 mb-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Utilisez notre nomenclature
        </h3>
      </div>
      <div>
        <a
          target="_blank"
          className="dl-nomenclature mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-base font-medium hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
          href="https://qualifelec.sharepoint.com/:b:/s/prosqualifelec/ERCo0tLp2nBCveUXP1ezw2QBUlPqZQsYwZlnL5RGDff73g?e=aencFu"
          onClick={() => {sendAnalyticsClick('Body', 'click_norm')}}
        >
          <DownloadIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          Nomenclature
        </a>
      </div>
      <Disclosure defaultOpen={true} as="div" className="mt-2">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white bg-qe-light-blue rounded-lg hover:bg-qe-medium-blue focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
              <span>Qualifications :</span>
              {!open && <PlusIcon className="w-5 h-5 text-white" />}
              {open && <MinusIcon className="w-5 h-5 text-white" />}
            </Disclosure.Button>
            <Disclosure.Panel onClick={() => {getAnalyticsType('qualifications')}} className="px-4 pt-4 pb-2 text-sm text-gray-500">
              <CustomRefinement
                facetOrdering={false}
                showMore
                attribute="qualifications"
                showMoreLimit={500}
                searchState={searchState}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Disclosure defaultOpen={true} as="div" className="mt-2">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white bg-qe-light-blue rounded-lg hover:bg-qe-medium-blue focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
              <span>Indices :</span>
              {!open && <PlusIcon className="w-5 h-5 text-white" />}
              {open && <MinusIcon className="w-5 h-5 text-white" />}
            </Disclosure.Button>
            <Disclosure.Panel onClick={() => {getAnalyticsType('indices')}}
             className="px-4 pt-4 pb-2 text-sm text-gray-500">
              <CustomRefinement
                facetOrdering={false}
                showMore
                attribute="indices"
                showMoreLimit={500}
                searchState={searchState}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Disclosure defaultOpen={true} as="div" className="mt-2">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white bg-qe-light-blue rounded-lg hover:bg-qe-medium-blue focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
              <span>Classes (Effectifs) :</span>
              {!open && <PlusIcon className="w-5 h-5 text-white" />}
              {open && <MinusIcon className="w-5 h-5 text-white" />}
            </Disclosure.Button>
            <Disclosure.Panel onClick={() => {getAnalyticsType('classes')}} className="px-4 pt-4 pb-2 text-sm text-gray-500">
              <CustomRefinement
                facetOrdering={false}
                showMore
                customLabelRender={getEffectif}
                attribute="classes"
                showMoreLimit={500}
                searchState={searchState}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Disclosure defaultOpen={true} as="div" className="mt-2">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white bg-qe-light-blue rounded-lg hover:bg-qe-medium-blue focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
              <span>Mentions :</span>
              {!open && <PlusIcon className="w-5 h-5 text-white" />}
              {open && <MinusIcon className="w-5 h-5 text-white" />}
            </Disclosure.Button>
            <Disclosure.Panel onClick={() => {getAnalyticsType('mentions')}} className="px-4 pt-4 pb-2 text-sm text-gray-500">
              <CustomRefinement
                facetOrdering={false}
                showMore
                attribute="mentions"
                showMoreLimit={500}
                searchState={searchState}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Disclosure defaultOpen={true} as="div" className="mt-2">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white bg-qe-light-blue rounded-lg hover:bg-qe-medium-blue focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
              <span>Domaines :</span>
              {!open && <PlusIcon className="w-5 h-5 text-white" />}
              {open && <MinusIcon className="w-5 h-5 text-white" />}
            </Disclosure.Button>
            <Disclosure.Panel onClick={() => {getAnalyticsType('domaines')}} className="px-4 pt-4 pb-2 text-sm text-gray-500">
              <CustomRefinement
                facetOrdering={false}
                showMore
                attribute="domains"
                showMoreLimit={500}
                searchState={searchState}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};
