import React, { useState } from "react";
import { connectRefinementList } from "react-instantsearch-dom";
import { printNumbersWithSpaceAsThousandsSeparator } from "../../helpers";
import { ClearRefinements } from "react-instantsearch-dom";
function removeItem(array, item) {
  for (var i in array) {
    if (array[i] == item) {
      array.splice(i, 1);
      break;
    }
  }
}
const updateRefinement = (itemSelected, checked, currentRefinement) => {
  const getFullLabels = (item) => {
    let labels = [];
    if (item.preffixes.length) {
      labels = item.preffixes.map((p) => p + "$$$" + item.label);
    } else {
      labels = [item.label];
    }
    return labels;
  };

  const labels = getFullLabels(itemSelected);
  if (checked) {
    labels.forEach((l) => {
      if (!currentRefinement.includes(l)) {
        currentRefinement.push(l);
      }
    });
  } else {
    labels.forEach((l) => {
      removeItem(currentRefinement, l);
    });
  }

  return currentRefinement;
};

const regroupByValue = (items) => {
  let datas = {};
  items.forEach((item) => {
    let preffix = null;
    let label = item.label;
    let count = null;
    if (item.label.indexOf("$$$") != -1) {
      const split = item.label.split("$$$");
      preffix = split[0];
      label = split[1];
      count = null;
    } else {
      count = item.count;
    }
    if (!datas[label]) {
      datas[label] = {
        label: label,
        preffixes: [],
        count: count,
        isRefined: item.isRefined,
      };
    }

    if (preffix && !datas[label].preffixes.includes(preffix)) {
      datas[label].preffixes.push(preffix);
    }
  });
  datas = Object.values(datas);
  return datas;
};

export const CustomRefinement = connectRefinementList(
  ({
    items,
    currentRefinement,
    isFromSearch,
    refine,
    searchForItems,
    createURL,
    customLabelRender,
    attribute,
    searchState,
  }) => {
    const [showMore, setShowMore] = useState(false);
    const showMoreLimit = 9;

    if (!items.length) {
      return (
        <>
          <p className="text-sm text-gray-500">Filtre non applicable.</p>
          <ClearRefinements
            clearsQuery
            translations={{
              reset: "Réinitialiser les filtres",
            }}
          />
        </>
      );
    }
    const groupedItems = regroupByValue(items);

    const hasQualificationRefinement =
      searchState.searchState &&
      searchState.searchState.refinementList &&
      searchState.searchState.refinementList.qualifications;

    return (
      <fieldset className="space-y-1">
        <legend className="sr-only">Refinement</legend>
        {groupedItems.map((item, index) => {
          let label = item.label;
          if (customLabelRender) {
            label = customLabelRender(label);
          }
          let filter = false;
          if (item.preffixes.length) {
            filter =
              hasQualificationRefinement &&
              !searchState.searchState.refinementList.qualifications.find(
                (qFilter) => item.preffixes.includes(qFilter)
              ) &&
              !item.isRefined;
          }
          return (
            (index <= showMoreLimit || (index > showMoreLimit && showMore)) &&
            !filter && (
              <div key={item.label} className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    autoComplete="off"
                    checked={item.isRefined}
                    id={label + "-" + index}
                    name={label + "-" + index}
                    type="checkbox"
                    onChange={(event) => {
                      const checked = event.target.checked;
                      const newRefinement = updateRefinement(
                        item,
                        checked,
                        currentRefinement
                      );
                      return refine(newRefinement);
                    }}
                    className="focus:ring-qe-orange h-4 w-4 text-qe-orange border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor={label + "-" + index}
                    className="text-gray-700"
                  >
                    {label}
                  </label>

                  {item.count && (
                    <span className="ml-3 inline-flex items-center px-1 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                      {printNumbersWithSpaceAsThousandsSeparator(item.count)}
                    </span>
                  )}
                </div>
              </div>
            )
          );
        })}
        {groupedItems.length > showMoreLimit + 1 && (
          <div className="pt-2">
            <button
              onClick={() => setShowMore(!showMore)}
              type="button"
              className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-qe-orange"
            >
              {showMore ? "Voir moins" : "Voir plus"}
            </button>
          </div>
        )}
      </fieldset>
    );
  }
);
