import { InformationCircleIcon } from "@heroicons/react/solid";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as geolib from "geolib";
import "instantsearch.css/themes/satellite.css";
import qs from "qs";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Helmet } from "react-helmet";
import { Configure, InstantSearch } from "react-instantsearch-dom";
import { Control, GeoSearch, Marker } from "react-instantsearch-dom-maps";
import CompanyCard from "../components/company-card/company-card";
import { Facets } from "../components/facets/facets";
import { CustomHits } from "../components/hits/hits";
import { CustomSearchBox } from "../components/search-box/search-box";
import { CustomStats } from "../components/stats/stats";
import { classNames, urlToSearchState } from "../helpers";
import { searchClient } from "../shared/search-client";
import { sendAnalyticsClick } from "../analytics/analytics";


const createURL = (state) => {
  return `?${qs.stringify(state)}`;
};

let isStart = true;

const searchStateToUrl = (searchState) =>
  searchState ? createURL(searchState) : "";

const DEBOUNCE_TIME = 400;

const tabs = [
  { name: "Carte", href: "#", current: true },
  { name: "Liste", href: "#", current: false },
];

const InfoWindow =
  typeof window !== "undefined" ? new window.google.maps.InfoWindow() : null;

function renderGeoHit(hit) {
  const onClickMarker = ({ hit, marker }) => {
    if (InfoWindow.getMap()) {
      InfoWindow.close();
    }

    const content = ReactDOMServer.renderToString(
      <CompanyCard company={hit} />
    );

    InfoWindow.setContent(content);

    InfoWindow.open(marker.getMap(), marker);

    const data = {
      SIRET: hit.siret,
      name: hit.name
    };
    
    sendAnalyticsClick('Carte', 'click_marker', data);
  };

  return (
    <Marker
      key={hit.objectID}
      hit={hit}
      anchor={{ x: 0, y: 5 }}
      onClick={({ marker }) => {
        onClickMarker({
          hit,
          marker,
        });
      }}
    />
  );
}
/**
 * Find the closest radius index to the given distance in const marks
 * 
 * const marks = [
  { label: "1km", value: 2000 },
  { label: "5km", value: 10000 },
  { label: "10km", value: 20000 },
  { label: "20km", value: 40000 },
  { label: "50km", value: 100000 },
  { label: "100km", value: 200000 },
];
 * 
 * @param {int} distance 
 * @returns 
 */
function find_close_to_radius_index(distance) {
  let min = 100000000
  let index = 0
  for (let i = 0; i < marks.length; i++) {
    let diff = Math.abs(marks[i].value - distance)
    if (diff < min) {
      min = diff
      index = i
    }
  }
  return index
}

// funtion used to transform google api bouding box to string of format "latNE,lngNE,latSW,lngSW"
function getBoudingBoxStr(boundingBox) {
  if (boundingBox) {
    if (!boundingBox.northEast) {
      return boundingBox;
    }
    return [
      boundingBox.northEast.lat,
      boundingBox.northEast.lng,
      boundingBox.southWest.lat,
      boundingBox.southWest.lng,
    ].join(", ");
  }
  return undefined;
}

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  return geolib.getDistance(
    { latitude: lat1, longitude: lon1 },
    { latitude: lat2, longitude: lon2 }
  );
}

const marks = [
  { label: "1km", value: 2000 },
  { label: "5km", value: 10000 },
  { label: "10km", value: 20000 },
  { label: "20km", value: 40000 },
  { label: "50km", value: 100000 },
  { label: "100km", value: 200000 },
];
const label_by_index = {};

marks.forEach((mark, index) => {
  label_by_index[index] = mark.label;
});

export default function Index() {
  const location = useLocation();

  const sParams = urlToSearchState(location);

  const [searchState, setSearchState] = useState(sParams);

  const debouncedSetStateRef = useRef(null);

  const renderHit = ({ hits }) => {
    if (hits.length === 1 && isStart === true) {
      isStart = false;

      let boundingBox = {};

      if (searchState.boundingBox === undefined) {
        let center = {
          latitude: hits[0]._geoloc.lat,
          longitude: hits[0]._geoloc.lng,
        };

        let newNe = geolib.computeDestinationPoint(center, 5000, 45);

        let newSw = geolib.computeDestinationPoint(center, 5000, 225);

        boundingBox = {
          northEast: {
            lat: newNe.latitude,
            lng: newNe.longitude,
          },
          southWest: {
            lat: newSw.latitude,
            lng: newSw.longitude,
          },
        };
      } else {
        boundingBox = {
          northEast: {
            lat: searchState.boundingBox.northEast.lat,
            lng: searchState.boundingBox.northEast.lng,
          },
          southWest: {
            lat: searchState.boundingBox.southWest.lat,
            lng: searchState.boundingBox.southWest.lng,
          },
        };
      }

      let dist = getDistanceFromLatLonInKm(
        boundingBox.northEast.lat,
        boundingBox.northEast.lng,
        boundingBox.southWest.lat,
        boundingBox.southWest.lng
      );

      let radiusIndex = find_close_to_radius_index(dist);

      setRadiusState(radiusIndex);

      setTimeout(() => {
        setSearchState({
          ...searchState,
          boundingBox: boundingBox,
        });
      }, 200);
    }

    return (
      <div>
        <Control
          translations={{
            redo: "Rechercher dans cette zone",
            control: "Rechercher en déplaçant la carte",
          }}
        />
        {hits.map(renderGeoHit)}
      </div>
    );
  };

  function onSearchStateChange(updatedSearchState) {
    if (
      updatedSearchState.boundingBox !== undefined &&
      updatedSearchState.boundingBox.northEast
      ) {
        const distance = getDistanceFromLatLonInKm(
          updatedSearchState.boundingBox.northEast.lat,
          updatedSearchState.boundingBox.northEast.lng,
          updatedSearchState.boundingBox.southWest.lat,
          updatedSearchState.boundingBox.southWest.lng
          );
          
          if (
            distance < 10000 &&
            updatedSearchState.isFromSearchBox &&
            updatedSearchState.isFromSearchBox === true
            ) {
              setRadiusState(1);
            } else{
              let radiusIndex = find_close_to_radius_index(distance);
              setRadiusState(radiusIndex);
            }
          }
          clearTimeout(debouncedSetStateRef.current);
          
          debouncedSetStateRef.current = setTimeout(() => {
            navigate(searchStateToUrl(updatedSearchState));
    }, DEBOUNCE_TIME);

    setSearchState(updatedSearchState);
  }

  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    setSearchState(urlToSearchState(location));
  }, [location]);

  const [configuration, setConfiguration] = useState({
    hitsPerPage: 100,
  });
  
  let defaultRadiusMark = marks.length - 1;
  if (sParams.radius === '5000') {
    defaultRadiusMark = 2;
  }
  const [radiusState, setRadiusState] = useState(defaultRadiusMark);

  function onRadiusStateChange(radius_index) {
    if (
      !!searchState &&
      !!searchState.boundingBox &&
      !!searchState.boundingBox.northEast &&
      !!searchState.boundingBox.southWest
    ) {
      const ne = searchState.boundingBox.northEast;
      const sw = searchState.boundingBox.southWest;
      const radius = marks[radius_index].value;

      const center = geolib.getCenterOfBounds([sw, ne]);

      const directionNe = geolib.getRhumbLineBearing(center, ne);

      const directionSw = geolib.getRhumbLineBearing(center, sw);

      let newNe = geolib.computeDestinationPoint(
        center,
        radius / 2,
        directionNe
      );
      let newSw = geolib.computeDestinationPoint(
        center,
        radius / 2,
        directionSw
      );

      let dist = getDistanceFromLatLonInKm(
        newNe.latitude,
        newNe.longitude,
        newSw.latitude,
        newSw.longitude
      );

      let ri= find_close_to_radius_index(dist);

      setRadiusState(ri);

      setSearchState({
        ...searchState,
        boundingBox: {
          northEast: {
            lat: newNe.latitude,
            lng: newNe.longitude,
          },
          southWest: {
            lat: newSw.latitude,
            lng: newSw.longitude,
          },
        },
      });
      const analyticsData = {
        distance: marks[radius_index].label
      }
      sendAnalyticsClick('Menu', 'click_distance', analyticsData);
    }
  }

  const algoliaIndex = process.env.GATSBY_ALGOLIA_COLLECTION
    ? process.env.GATSBY_ALGOLIA_COLLECTION
    : "correspondants";
  return (
    <>
      <Helmet>
        <title>Qualifelec - Rechercher un qualifié</title>
        <meta
          name="google-site-verification"
          content="kHqnZTm4tK9OneGze9ss2bqgArAG_IyxmSEZTYq1SDk"
        />
        <meta 
          name="description" 
          content="Explorez le moteur de recherche de Qualifelec pour trouver les professionnels du génie électrique qualifiés partout en France. Accédez facilement à leurs certificats, découvrez leurs compétences et localisez les experts près de chez vous."
        />
        <meta 
          name="keywords" 
          content="électricien, électricité, génie électrique, énergies renouvelables, travaux électriques, rénovation énergétique, Qualifelec, IRVE, borne de recharge pour véhicule électrique, SPV, installation de panneaux solaires photovoltaïques, transition énergétique, annuaire, professionnel qualifié, installation de borne de recharge, qualifié, certificat, RGE, électricien près de chez moi, Qualit'ENR, Qualitenr, qualit' enr"
        />
        <meta 
          name="author" 
          content="Qualifelec"
        />
        <meta 
          property="og:title" 
          content="Qualifelec - Rechercher un qualifié"
        />
        <meta 
          property="og:description" 
          content="Explorez le moteur de recherche de Qualifelec pour trouver les professionnels du génie électrique qualifiés partout en France. Accédez facilement à leurs certificats, découvrez leurs compétences et localisez les experts près de chez vous."
        />
        <meta 
          property="og:image" 
          content="https://data.qualifelec.fr/recherche/static/651bfdb7fb9176eef0af4b9f6455f549/3f2a9/logo.webp"
        />
        <meta 
          property="og:url" 
          content="https://data.qualifelec.fr/recherche/"
        />
        <meta 
          property="og:type" 
          content="website"
        />
        <link 
          rel="canonical" 
          href="https://data.qualifelec.fr/recherche/" nonce="">
        </link>
      </Helmet>
      <InstantSearch
        searchClient={searchClient}
        indexName={algoliaIndex}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
        refresh={true}
      >
        <Configure
          hitsPerPage={configuration.hitsPerPage}
          //insideBoundingBox={configuration.insideBoundingBox}
        />

        <div className="min-h-full">
          <div className=" bg-qe-light-blue">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 bg-qe-light-blue">
              <div className="relative py-5 flex items-center justify-center lg:justify-between">
                {/* Logo */}
                <div className="absolute left-0 flex-shrink-0 hidden md:block lg:static ">
                  <a href="/recherche">
                    <span className="sr-only">Qualifelec</span>
                    <StaticImage
                      src="../images/logo.png"
                      alt="Logo qualifelec"
                      placeholder="none"
                      width={100}
                      height={129}
                      onClick={() => {
                        console.log('clic logo');
                        sendAnalyticsClick('Menu', 'click_qualifelec_logo');
                      }}
                    />
                  </a>
                </div>

                {typeof window !== "undefined" ? (
                  <CustomSearchBox
                    configuration={configuration}
                    setConfiguration={(conf) => {
                      setConfiguration(conf);
                    }}
                    searchState={searchState}
                    setSearchState={onSearchStateChange}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <main className="py-8">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              <h1 className="sr-only">Qualifelec</h1>
              {/* Main 3 column grid */}
              <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
                {/* Left column */}
                <div className="grid grid-cols-1 gap-4">
                  <section aria-labelledby="section-2-title">
                    <h2 className="sr-only" id="section-2-title">
                      Affinez votre recherche
                    </h2>
                    <div className="rounded-lg bg-white overflow-hidden shadow">
                      <div className="p-5">
                        <Facets searchState={searchState} />
                      </div>
                    </div>
                  </section>
                </div>

                {/* Right column */}
                <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                  <section aria-labelledby="section-1-title">
                    <h2 className="sr-only" id="section-1-title">
                      Résultats
                    </h2>
                    <div className="rounded-lg bg-white overflow-hidden shadow">
                      <div className="p-5">
                        <div className="border-b border-gray-200">
                          <div className="sm:flex sm:items-baseline">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              Résultats <CustomStats />{" "}
                            </h3>
                            <div className="mt-4 sm:mt-0 sm:ml-10">
                              <nav className="-mb-px flex space-x-8">
                                {tabs.map((tab, index) => (
                                  <button
                                    key={tab.name}
                                    onClick={() => {
                                      const nConfig = {
                                        ...configuration,
                                        /*insideBoundingBox: getBoudingBoxStr(
                                                                                  searchState.boundingBox ||
                                                                                    (searchState.configure &&
                                                                                      searchState.configure
                                                                                        .insideBoundingBox)
                                                                                ),*/
                                      };
                                      nConfig.hitsPerPage =
                                        index === 1 ? 10 : 100;
                                      setConfiguration(nConfig);
                                      setCurrentTab(index);
                                      const name = 'click_' + tab.name.toLowerCase();
                                      sendAnalyticsClick('Body', name);
                                    }}
                                    className={classNames(
                                      index === currentTab
                                        ? "border-qe-orange text-qe-orange"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                      "whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                                    )}
                                    aria-current={
                                      tab.current ? "page" : undefined
                                    }
                                  >
                                    {tab.name}
                                  </button>
                                ))}
                              </nav>
                            </div>
                            <span className="flex-1" />
                          </div>
                        </div>
                        <div className={currentTab === 1 ? "show" : "hide"}>
                          <CustomHits />
                        </div>

                        <div className={currentTab === 0 ? "show" : "hide"}>
                          <div className="rounded-md bg-blue-50 p-4 mt-2">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <InformationCircleIcon
                                  className="h-5 w-5 text-blue-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-3 flex-1 md:flex md:justify-between">
                                <p className="text-sm text-blue-700">
                                  La visualisation est limitée à{" "}
                                  {configuration.hitsPerPage} résultats.
                                  Déplacez et/ou zoomez la carte pour affiner
                                  votre recherche.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="w-full h-16 mb-4 mt-2">
                            <form>
                              <div>
                                <span>Distance</span>
                                <div className="w-full px-4 mt-2">
                                  <Slider
                                    id="distance"
                                    min={0}
                                    max={marks.length - 1}
                                    value={radiusState}
                                    onChange={onRadiusStateChange}
                                    marks={label_by_index}
                                    step={null}
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                          <div
                            style={{ height: "65vh", width: "100%" }}
                            className="mt-2"
                          >
                            <GeoSearch
                              enableRefineOnMapMove={true}
                              google={
                                typeof window !== "undefined"
                                  ? window.google
                                  : null
                              }
                            >
                              {renderHit}
                            </GeoSearch>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </main>

          <footer>
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
              <div className="border-t border-gray-200 py-8 text-sm text-gray-500 text-center sm:text-left">
                <span className="block sm:inline">
                  &copy; {new Date().getFullYear()} Qualifelec.
                </span>{" "}
                <span className="block sm:inline">Tous droits réservés.</span>
              </div>
            </div>
          </footer>
        </div>
      </InstantSearch>
    </>
  );
}
