import React from "react";
import { connectStats } from "react-instantsearch-dom";
import { printNumbersWithSpaceAsThousandsSeparator } from "../../helpers";

export const CustomStats = connectStats(({ nbHits, processingTimeMS }) => (
  <p className="inline ml-2 mt-1 text-sm text-gray-500 truncate">
    ({printNumbersWithSpaceAsThousandsSeparator(nbHits)} en {processingTimeMS}{" "}
    ms)
  </p>
));
