import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";
import React from "react";
import { connectPagination } from "react-instantsearch-dom";

export const CustomPagination = connectPagination(
  ({ currentRefinement, nbPages, refine, createURL }) => (
    <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
      <div className="-mt-px w-0 flex-1 flex">
        <a
          href={createURL(currentRefinement - 1)}
          disabled={currentRefinement === 1}
          onClick={(evt) => {
            evt.preventDefault();
            if (currentRefinement - 1 > 0) {
              refine(currentRefinement - 1);
            }
          }}
          className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
        >
          <ArrowNarrowLeftIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Précédent
        </a>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {new Array(nbPages).fill(null).map((_, index) => {
          const PADDING = 3;
          const page = index + 1;
          const shouldDisplay3dot =
            page > 1 &&
            page < nbPages &&
            (page == currentRefinement - PADDING ||
              page == currentRefinement + PADDING);

          const shouldNotDisplay =
            page !== 1 &&
            page !== nbPages &&
            (page < currentRefinement - PADDING ||
              page > currentRefinement + PADDING);

          const itemClass =
            currentRefinement === page
              ? "border-qe-orange text-qe-orange"
              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300";

          if (shouldNotDisplay) {
            return null;
          }
          if (shouldDisplay3dot) {
            return (
              <span
                key={index}
                className="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
              >
                ...
              </span>
            );
          }
          return (
            <a
              key={index}
              onClick={(event) => {
                event.preventDefault();
                refine(page);
              }}
              href={createURL(page)}
              className={`border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium ${itemClass}`}
            >
              {page}
            </a>
          );
        })}
      </div>
      <div className="-mt-px w-0 flex-1 flex justify-end">
        <a
          href={createURL(currentRefinement + 1)}
          disabled={currentRefinement === nbPages}
          onClick={(evt) => {
            evt.preventDefault();
            if (currentRefinement < nbPages) {
              refine(currentRefinement + 1);
            }
          }}
          className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
        >
          Suivant
          <ArrowNarrowRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </a>
      </div>
    </nav>
  )
);
