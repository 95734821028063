import { LocationMarkerIcon, SearchIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import { sendAnalyticsInput } from "../../analytics/analytics";

import * as geolib from "geolib";

const countries = [
  // List France + DOM TOM
  "FR",
  "NC",
  "MQ",
  "BL",
  "GP",
  "MF",
  "PF",
  "PM",
  "RE",
  "TF",
  "WF",
];

const geolocationAPI =
  typeof window !== "undefined" ? navigator.geolocation : null;

export const CustomSearchBox = connectSearchBox(
  ({
    currentRefinement,
    refine,
    configuration,
    setConfiguration,
    searchState,
    setSearchState,
  }) => {

  const [inputValue, setInputValue] = useState(currentRefinement);
  
  // Used to make sure the refine is actualised directly but the analytics is sent only after 1.5s of inactivity
  useEffect(() => {
    const timerId = setTimeout(() => {
      refine(inputValue);
    }, 0); 
    return () => clearTimeout(timerId);
  }, [inputValue, refine]);

  useEffect(() => {
    const delayInMilliseconds = 1500;
    const timerId = setTimeout(() => {
      sendAnalyticsInput('Menu', 'text_what', { input: inputValue });
    }, delayInMilliseconds);
    return () => clearTimeout(timerId);
  }, [inputValue]);

  const handleInputChange = (event) => {
    const value = event.currentTarget.value;
    setInputValue(value);
  };

    const input = document.getElementById("location");

    useEffect(() => {
      if (!geolocationAPI) {
        console.error("Geolocation API is not available in your browser!");
      } else {
        const autocomplete = new window.google.maps.places.Autocomplete(input, {
          fields: ["address_components", "geometry", "icon", "name"],
          types: ["(regions)"],
        });

        autocomplete.setComponentRestrictions({
          country: countries,
        });
        // Add full list of countries autocomplete restriction

        // Add google listener when place is selected
        autocomplete.addListener("place_changed", (forcedPlace) => {
          let place = forcedPlace ? forcedPlace : autocomplete.getPlace();
          console.log(place)

          sendAnalyticsInput('Menu', 'text_where', {
            place: place.name,
          });

          if (place && place.geometry) {
            let { bounds } = place.geometry;
            if (!bounds) bounds = place.geometry.viewport;

            /*setConfiguration({
                          ...configuration,
                          insideBoundingBox: [
                            bounds.getNorthEast().lat(),
                            bounds.getNorthEast().lng(),
                            bounds.getSouthWest().lat(),
                            bounds.getSouthWest().lng(),
                          ].join(", "),
                        });*/

            return setSearchState({
              ...searchState,
              boundingBox: {
                northEast: {
                  lat: bounds.getNorthEast().lat(),
                  lng: bounds.getNorthEast().lng(),
                },
                southWest: {
                  lat: bounds.getSouthWest().lat(),
                  lng: bounds.getSouthWest().lng(),
                },
              },
              isFromSearchBox: true,
            });
          }
        });

        if (searchState.boundingBox) {
          const { northEast, southWest } = searchState.boundingBox;
          const { lat, lng } = geolib.getCenter([northEast, southWest]);
          setConfiguration({
            ...configuration,
            aroundLatLng: `${lat}, ${lng}`,
          });
        }

        if (!searchState.query) {
          geolocationAPI.getCurrentPosition(
            (position) => {
              const { coords } = position;

              var latlng = null;
              if (configuration.aroundLatLng) {
                latlng = new window.google.maps.LatLng(
                  configuration.aroundLatLng.split(",")[0],
                  configuration.aroundLatLng.split(",")[1]
                );
              } else {
                latlng = new window.google.maps.LatLng(
                  coords.latitude,
                  coords.longitude
                );
              }

              // This is making the Geocode request
              var geocoder = new window.google.maps.Geocoder();
              let address = null;
              const MINIMUM_DIAGONAL_METERS = 2000;
              geocoder.geocode({ latLng: latlng }, (results, status) => {
                // This is checking to see if the Geoeode Status is OK before proceeding
                if (
                  status === window.google.maps.GeocoderStatus.OK &&
                  results.length
                ) {
                  const result = results
                    .filter((r) => r.geometry.bounds)
                    .find((r) => {
                      const distance = geolib.getDistance(
                        r.geometry.bounds.getSouthWest().toJSON(),
                        r.geometry.bounds.getNorthEast().toJSON()
                      );
                      return distance > MINIMUM_DIAGONAL_METERS;
                    });
                  if (result) {
                    address = result.formatted_address;
                    if (input !== null) {
                      input.value = address;
                      window.google.maps.event.trigger(
                        autocomplete,
                        "place_changed",
                        result
                      );
                    }
                  }
                }
              });
            },
            (error) => {
              console.error("Something went wrong getting your position!");
            }
          );
        }
      }
    }, [input]);

    return (
      <>
        <div className="sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-3xl w-full mx-auto p-4 bg-qe-light-blue">
          <label htmlFor="query" className="sr-only">
            Entreprise, spécialité, nature de travaux...
          </label>
          <h1 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate mb-3">
            Trouvez votre professionnel Qualifelec
          </h1>
          <div className="flex rounded-md shadow-sm">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                autoComplete="off"
                type="search"
                name="query"
                id="query"
                value={currentRefinement}
                onChange={handleInputChange}
                focusout
                className="focus:ring-qe-dark-blue-500 focus:ring-qe-dark-blue-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                placeholder="Entreprise, spécialité, nature de travaux..."
              />
            </div>
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <LocationMarkerIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                autoComplete="off"
                name="location"
                id="location"
                className="py-4 focus:ring-qe-dark-blue-500 focus:border-qe-dark-blue-500 block w-full rounded-none rounded-r-md pl-10 sm:text-sm border-gray-300"
                placeholder="Où"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
);
