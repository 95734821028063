import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState } from "react";
import { connectHits } from "react-instantsearch-dom";
import CompanyCard from "../company-card/company-card";
import NoHit from "../no-hit/no-hit";
import { CustomPagination } from "../pagination/pagination";
import { CustomCurrentRefinements } from "../refinement-tag/refinement-tag";

const Hit = ({ hit, setSelectedCompany, setOpen }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-qe-dark-blue-500">
      <div className="flex-shrink-0">{/* prettier-ignore */}</div>
      <div
        className="flex-1 min-w-0 cursor-pointer"
        onClick={() => {
          setSelectedCompany(hit);
          setOpen(true);
        }}
      >
        <span className="absolute inset-0" aria-hidden="true" />
        <p className="text-sm font-bold text-qe-light-blue">
          {hit.name}{" "}
          {["RGE", "PRGE"].some((e) => hit.mentions.includes(e)) && (
            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
              RGE
            </span>
          )}{" "}
        </p>
        <p className="text-sm text-gray-500 truncate">
          {hit.address_line_1} {hit.address_line_2}
        </p>
        <p className="text-sm text-gray-500 truncate">
          {hit.zip} {hit.city}
        </p>
        <p className="mt-3 text-sm text-gray-500 truncate">
          SIRET : {hit.siret}
        </p>
      </div>
    </div>
  );
};

export const CustomHits = connectHits(({ hits }) => {
  if (!hits.length) return <NoHit />;
  const [open, setOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const cancelButtonRef = useRef(null);

  return (
    <>
      <CustomCurrentRefinements />
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-3 my-5">
        {hits.map((hit, index) => (
          <Hit
            key={hit.name + index}
            hit={hit}
            setSelectedCompany={setSelectedCompany}
            setOpen={setOpen}
          />
        ))}
      </div>

      <CustomPagination />

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <CompanyCard company={selectedCompany} withPadding />
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Fermer
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
});
